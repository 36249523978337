<template lang="pug">
    .page
        a-modal(v-model="visible"
            @ok="submitAdd"
            title="新建用户")
            a-form(:form="form" :label-col="labelCol" :wrapper-col="wrapperCol")
                a-form-item(label="用户名")
                    a-input(v-decorator="['name', {rules: [{required: true, message: '请输入用户名'}] }]")
                a-form-item(label="密码")
                    a-input(type="password" v-decorator="['password', {rules: [{required: true, message: '请输入密码'}] }]")
    
        a-drawer(title="编辑用户"
            @close="editVisible=false"
            :width="500"
            :visible="editVisible"
            :destroyOnClose="true")
            a-form
                a-form-item(label="密码")
                    span {{userEditing.password}}
    
                a-form-item(label="修改密码")
                    a-input(v-model="newPassword")
                    a-button(type="primary" @click="submitUpPasswd") 提交
                a-form-item(label="修改权限")
                    a-checkbox-group(mode="tags" 
                        v-model="newPerms"
                        :default-value="userEditing.perms"
                        :options="$perms"
                        size="small")
                        
                    a-button(type="primary" @click="submitUpPerms") 提交
    
        a-button(type="primary" 
            @click="visible=true"
            style="margin-bottom:10px")
            a-icon(type="user-add")
            span 新建用户
    
        a-table(:dataSource="items"
            rowKey="_id"
            size="small"
            :pagination="{pageSize:20}"
            :columns="columns")
            template(slot="operator" slot-scope="record")
                a(@click="handleEdit(record)")
                    a-icon(type="edit")
                a-divider(type="vertical")
                a-popconfirm(title="确认删除？" @confirm="submitDel(record._id)")
                    a 
                        a-icon(type="delete")
            template(slot="perms" slot-scope="perms, record")
                a-tag(v-for="p in $perms" :color="p.color" :key="p.value" v-if="perms.includes(p.value)") {{p.label}}

    </template>
    
    <script>
    import moment from 'moment';
    
    export default {
        data() {
            return {
                newPassword: "",
                newPerms: [],
                userEditing: {
                    password: '',
                    perms: []
                },
                editVisible: false,
                visible: false,
                form: this.$form.createForm(this, {name: 'add'}),
                items: [],
                columns: [
                    {
                        title: '用户名',
                        dataIndex: 'name'
                    },
                    // {
                    //     title: '创建时间',
                    //     dataIndex: 'createdAt',
                    // },
                    {
                        title: 'Last Login',
                        dataIndex: 'lastLogin'
                    },
                    {
                        title: '权限',
                        dataIndex: 'perms',
    
                        scopedSlots: {
                            customRender: 'perms',
                        }
                    },
                    {
                        title: '操作',
                        width: 90,
                        scopedSlots: {
                            customRender: 'operator',
                        }
                    }
                ]
            }
        },
        mounted() {
            this.getUsers();
        },
        methods: {
            handleEdit(record) {
                this.editVisible = true;
                this.newPassword = "";
                this.newPerms = record.perms;
                this.userEditing = record;
                
            },
           
            submitUpPerms() {
                this.$api.request("PUT", 'user/perms/'+this.userEditing._id, {
                    perms: this.newPerms
                })
                .then(() => {
                    this.$message.success("修改权限成功");
                    this.getUsers();
                });
            },
            submitUpPasswd() {
                this.$api.request("PUT", 'user/password/'+this.userEditing._id, {
                    password: this.newPassword
                })
                .then(() => {
                    this.$message.success("修改密码成功");
                    this.getUsers();
                });
            },
            submitAdd() {
                this.form.validateFields((error, values) => {
                    if (!error) {
                        this.$api.request('POST', 'user', values)
                        .then(() => {
                            this.$message.success('创建成功');
                            this.getUsers();
                            this.visible = false;
                        })
                        .catch(() => {
                            this.$message.error('创建失败');
                        })
                    }
                })
            },
            submitDel(id) {
                this.$api.request('DELETE', 'user/'+id)
                .then(() => {
                    this.getUsers();
                });
            },
            getUsers() {
                this.$api.request("GET", 'users')
                .then(res => {
                    this.items = res.data.map(i => {
                        return {
                            ...i,
                            createdAt: moment(i.createdAt).format('lll'),
                            lastLogin: moment(i.lastLogin).fromNow(),
                        }
                    });
                });
            }
        }
    }
    </script>
    
    <style lang="less" scoped>
    .folder-item {
        display: flex;
        flex-direction: row;
        justify-content: center;
        flex-flow: row;
        font-size: 16px;
    }
    </style>
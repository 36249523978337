class IO {
    constructor() {
        this.ws = new WebSocket('/api/v2/ws/client');

        this.ws.onopen = () => {
            console.log('WebSocket opened');
        }

        this.ws.onmessage = (event) => {
            // const data = JSON.parse(event.data);
            console.log('WebSocket message received:', event.data);
            // TODO: Handle message
        }

        this.ws.onclose = () => {
            console.log('WebSocket closed');
            // TODO: Handle close
        }

        this.ws.onerror = (error) => {
            console.error('WebSocket error:', error);
            // TODO: Handle error
        }

        setInterval(() => {
            this.ws.send('ping');
        }, 3000);
    }

    register(userId) {
        this.userId = userId;
        // this.io.emit('register', userId);
    }

    init(store, notify) {
        this.$store = store;
        this.$notify = notify;
    }
}

const _io = new IO();

export default {
    install: function(Vue) {
        Vue.prototype.$io = _io;
    }
}
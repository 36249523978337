import axios from 'axios'
import _ from 'lodash';


class Api {
    constructor() {
        this.jwt = '';
        this.transport = axios.create({
            withCredentials: true
        });
    }

    init(store, message, router) {
        this.$store = store;
        this.$router = router;
        this.$message = message;
    }

    async download(url, data={}, version='v2') {
        try {
            let params = _.toPairs(data).map(([k,v]) => {
                return `${k}=${v}`
            }).join('&');

            let res = await this.transport({
                method: 'GET',
                crossDomain: true,
                url: `/api/${version}/${url}${params ? '?'+params : ''}`,
                data,
                responseType: 'blob',
                headers: {
                    'x-jwt': this.jwt
                }
            });

            this.jwt = res.headers['x-jwt'] || this.jwt;

            return res;
        }
        catch(e) {
            if (e.response.status == 401) {
                this.$store.commit('user/setMe', null);
                let path = this.$router.currentRoute.path;
                if (!/^\/(login|signup)/.test(path)) {
                    console.log(401)
                    this.$router.replace('/login');
                }
            }
            else {
                // this.$message.error(e.response.data);
            }
            throw e;
        }
    }

    jwtHeader() {
        return {
            headers: {
                'x-jwt': this.jwt
            }
        }
    }

    async request(method, url, data={}, version='v2') {
        try {
            let params = '';
            if (_.toLower(method) == 'get') {
                params = _.toPairs(data).map(([k,v]) => {
                    return `${k}=${v}`
                }).join('&');
            }

            let res = await this.transport({
                method,
                crossDomain: true,
                url: `/api/${version}/${url}${params ? '?'+params : ''}`,
                data,
                headers: {
                    'x-jwt': this.jwt
                }
            });

            this.jwt = res.headers['x-jwt'] || this.jwt;

            return res;
        }
        catch(e) {
            if (e.response.status == 401) {
                this.$store.commit('user/setMe', null);
                let path = this.$router.currentRoute.path;
                if (!/^\/(login|signup)/.test(path)) {
                    console.log(401)
                    this.$router.replace('/login');
                }
            }
            else {
                // this.$message.error(e.response.data);
            }
            throw e;
        }
    }
}

const _api = new Api();
export const api = _api;
export default {
    install: function(Vue) {
        Vue.prototype.$api = _api;
    }
}
<template lang="pug">
z-table(:columns="columns" 
    :uploadAble="true"
    @beforeUpload="beforeUpload"
    :route="`hkoption/${bookName}/stock`"
    rowKey="id") 
</template>

<script>
export default {
    props: ['bookName'],
    data() {
        return {
            columns: [
                {
                    title: 'Stock Code',
                    dataIndex: 'stock_code',
                    addAble: true,
                    searchAble: true,
                    scopedSlots: { customRender: 'inst' },
                },
                {
                    title: 'Current Stock Contract',
                    dataIndex: 'curr_stock_contract',
                    type: 'Number',
                    addAble: true,
                    editAble: true,
                },
                {
                    title: 'Price Cost',
                    dataIndex: 'price_cost',
                    type: 'Number',
                    addAble: true,
                    editAble: true,
                },
                {
                    title: 'Cumulative Stock Pnl',
                    dataIndex: 'cum_stock_pnl',
                    type: 'Number',
                    addAble: false,
                    editAble: false,
                },
                {
                    title: 'Realized Pnl',
                    dataIndex: 'realized_pnl',
                    type: 'Number',
                    addAble: true,
                    editAble: true,
                },
                {
                    title: 'Floating Pnl',
                    dataIndex: 'floating_pnl',
                    addAble: false,
                    editAble: false,
                }
            ]
        }
    },
    methods: {
        beforeUpload(file) {
            let fr = new FileReader();

            let p = new Promise((resolve, reject) => {
                fr.onload = () => {
                    resolve(fr.result);
                };
                fr.onerror = reject;
            });
            
            fr.readAsText(file);

            p.then(data => {
                console.log(data);
            })
        }
    }
}
</script>
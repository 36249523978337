<template lang="pug">
    .page
        z-table(:columns="columns" route="tradeAccount")
    </template>
    
    <script>
    export default {
        data() {
            return {
                columns: [
                    {
                        title: 'ID',
                        dataIndex: '_id',
                    },
                    {
                        title: 'Name',
                        dataIndex: 'name',
                        addAble: true,
                        editAble: true,
                        searchAble: true,
                        scopedSlots: {
                            customRender: 'tradeAccount'
                        }
                    },
                    {
                        title: 'Account',
                        dataIndex: 'account',
                        addAble: true,
                        editAble: true,
                        searchAble: true,
                    },
                    {
                        title: 'Broker',
                        dataIndex: 'broker',
                        addAble: true,
                        editAble: true,
                        searchAble: true,
                    },
                    {
                        title: 'Host',
                        dataIndex: 'host',
                        addAble: true,
                        editAble: true,
                        searchAble: true,
                    },
                    {
                        title: 'ClientId',
                        dataIndex: 'clientId',
                        addAble: true,
                        editAble: true,
                        searchAble: true,
                    },
                    {
                        title: 'desc',
                        dataIndex: 'desc',
                        addAble: true,
                        editAble: true,
                        searchAble: true,
                    },
                    {
                        title: 'User Count',
                        dataIndex: 'userCount',
                        
                    },
                    {
                        title: 'Created At',
                        dataIndex: 'createdAt',
                    }
                ]
            }
        }
    }
    </script>
<template lang="pug">
v-chart.chart(:option="option"
    :autoresize="true")
</template>

<script>
import VChart, {THEME_KEY} from 'vue-echarts';
import {LineChart} from 'echarts/charts';
import {use} from 'echarts/core';
import {
    TitleComponent,
    TooltipComponent,
    LegendComponent,
    SingleAxisComponent,
    GridComponent,
    AxisPointerComponent,
    TransformComponent
} from 'echarts/components';
import {CanvasRenderer} from 'echarts/renderers';

use([
    CanvasRenderer,
    GridComponent,
    TitleComponent,
    TooltipComponent,
    LegendComponent,
    SingleAxisComponent,
    AxisPointerComponent,
    LineChart,
    TransformComponent
]);

export default {
    props: ['option'],
    mounted() {

    },
    components: {
        VChart
    },
    provide: {
        [THEME_KEY]: "dark"
    }
}
</script>

<style lang="less" scoped>
.chart {
    height: 300px;
    min-width: 300px;
    // margin: 5px;
}
</style>
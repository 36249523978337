<template lang="pug">
    .page 
        a-drawer(:visible="userEditVisible"
            @close="userEditVisible=false"
            title="更新用户")
            a-checkbox-group(v-model="selectedUsers" :options="users.map(i => ({label: i.name, value: i._id}))")
    
            .submit-panel 
                a-button(type="primary" @click="submitEdit") Submit
        z-table(:columns="columns" 
            apiVersion="fs" 
            @link="handleLink"
            ref="myTable"
            :editAble="false"
            route="group")
            
    </template>
    
    <script>
    export default {
        data() {
            return {
                userEditVisible: false,
                users: [],
                selectedUsers: [],
                editUserId: '',
                columns: [
                    {
                        title: '名称',
                        dataIndex: 'name',
                        editAble: true,
                        addAble: true,
                        
                    },
                    {
                        title: 'User数量',
                        dataIndex: 'userCount',
                        scopedSlots: { customRender: 'link' },
                        // scopedSlots: { customRender: 'user' },
                    },
                    {
                        title: '文件数量',
                        dataIndex: 'fileCount'
                    },
                    {
                        title: 'createdAt',
                        dataIndex: 'createdAt',
                    }
                ]
            }
        },
        mounted() {
            this.getUsers();
        },
        methods: {
            handleLink(record) {
                this.userEditVisible = true;
                this.editUserId = record._id;
                this.selectedUsers = record.users;
    
                console.log(record);
            },
            getUsers() {
                this.$api.request("GET", 'users')
                .then(res => {
                    this.users = res.data;
                });
            },
            submitEdit() {
                this.$api.request('POST', 'group/update/users', {
                    users: this.selectedUsers,
                    _id: this.editUserId
                }, 'fs')
                .then(res => {
                    this.$refs.myTable.getData();
                });
            }
        }
    }
    </script>
<template lang="pug">
    .page
        a-table(:columns="columns" :dataSource="items" size="small")
</template>

<script>
export default {
    data() {        
        return {
            items: [],
            columns: [
                {
                    title: 'Stock Code',
                    dataIndex: 'stock_code',
                },
                {
                    title: 'Stock Name',
                    dataIndex: 'stock_name',
                },
                {
                    title: 'Hedging Gain Loss',
                    dataIndex: 'hedging_gain_loss',
                },
                {
                    title: 'Total Daily Pnl',
                    dataIndex: 'total_daily_pnl',
                },
                {
                    title: 'Total Pnl',
                    dataIndex: 'total_pnl',
                },
                {
                    title: 'Option Mark Up',
                    dataIndex: 'option_mark_up',
                },
                {
                    title: 'Option Daily Pnl',
                    dataIndex: 'option_daily_pnl',
                },
                {
                    title: 'Option Pnl',
                    dataIndex: 'option_pnl',
                },
                {
                    title: 'Instrinsic Value',
                    dataIndex: 'instrinsic_value',
                },
                {
                    title: 'Stock Daily Pnl',
                    dataIndex: 'stock_daily_pnl',
                },
                {
                    title: 'Cumulative Stock Pnl',
                    dataIndex: 'cumulative_stock_pnl',
                },
                {
                    title: 'Time Value',
                    dataIndex: 'time_value',
                },
                {
                    title: 'Notional',
                    dataIndex: 'notional',
                },
                {
                    title: 'Theta',
                    dataIndex: 'theta',
                },
                {
                    title: 'Vega',
                    dataIndex: 'vega',
                },
                {
                    title: 'Rho',
                    dataIndex: 'rho',
                },
                {
                    title: 'Gamma',
                    dataIndex: 'gamma',
                }
            ]
        }
    }
}
</script>
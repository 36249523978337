<template lang="pug">
.page 
    a-drawer(:visible="visible" 
        @close="visible=false"
        :destroyOnClose="true"
        :width="600"
        :title="name")
        z-table(:route="`tradeAccount/${id}/user`" 
            :editAble="false"
            :columns="columns")
    a(@click="handleClick") 
        my-icon(type="icon-account-book" style="margin-right:5px")
        span {{name}}
</template>

<script>
import MyIcon from '../components/MyIcon';

export default {
    props: ['id', 'name'],
    data() {
        return {
            visible: false,
            columns: [
                {
                    title: '用户名',
                    dataIndex: 'name',
                    addAble: true,
                },
                {
                    title: '权限',
                    dataIndex: 'perm',
                    addAble: true,
                    type: 'Enum',
                    enum: [
                        'readOnly',
                        'trade',
                        'admin',
                    ]
                }
            ],
            
        };
    },
    mounted() {
        
    },
    methods: {
        handleClick() {
            this.visible = true;
        }
    },
    computed: {
    },
    components: {
        MyIcon
    }
}
</script>
<template lang="pug">
.page 
    .ctl-bar 
        a-input-search(placeholder="input search text" 
            style="width: 200px; margin-right:20px" 
            v-model="search")
    a-table(:columns="columns" 
        :dataSource="items" 
        rowKey="_id" 
        :pagination="pagination" 
        :loading="loading"
        size="small")
</template>

<script>
import moment from 'moment/moment';
export default {
    data() {
        return {
            columns: [
                {
                    title: 'User',
                    dataIndex: 'user',
                },
                {
                    title: 'Route',
                    dataIndex: 'route',
                },
                {
                    title: 'Method',
                    dataIndex: 'method',
                },
                {
                    title: 'Code',
                    dataIndex: 'code',
                },
                {
                    title: 'IP',
                    dataIndex: 'ip',
                },
                {
                    title: 'Perm',
                    dataIndex: 'perm',
                },
                {
                    title: 'Time',
                    dataIndex: 'createdAt',
                }
            ],
            page: 1,
            total: 0,
            search: '',
            items: [],
            loading: false,
        }
    },
    mounted() {
        this.getData();
    },
    methods: {
        getData() {
            this.loading = true;
            this.$api.request('POST', 'logs', {
                pageSize: 20,
                pageSkip: this.page - 1,
                filter: this.search,
            })
            .then(res => {
                this.items = res.data.data.map(i => {
                    return {
                        ...i,
                        user: i.user ? i.user.name : '',
                        createdAt: moment(i.createdAt).format('lll'),
                    }
                });
                this.total = res.data.total;
                this.loading = false;
            })
        }
    },
    computed: {
        pagination() {
            return {
                onChange: (page, pageSize) => {
                    console.log(page);
                    this.page = page ;
                    this.getData();
                },
                pageSize: 20, 
                current: this.page,
                total: this.total,
            }
        }
    }
}
</script>
<template lang="pug">
div 
    a-modal(v-model="addVisible"
        @close="addVisible=false"
        @ok="submitAdd"
        title="创建")
        a-form(:form="addForm")
            a-form-item(v-for="(item,index) in addColumns" 
                :key="index" 
                :label="item.title" 
                :label-col="{span:6}" 
                :wrapper-col="{span:18}")

                a-input-number(v-if="item.type == 'Number'"
                    style="width:100%"
                    :min="item.min"
                    :max="item.max"
                    v-decorator="[item.dataIndex,]"
                    :placeholder="item.title")

                a-select(v-else-if="item.type == 'Enum'" 
                    v-decorator="[item.dataIndex,]")
                    a-select-option(v-for="i in item.enum" 
                        :value="i"
                        :key="i") {{ i }}

                a-input(
                    v-else
                    :placeholder="item.title" 
                    v-decorator="[item.dataIndex,]")

    a-drawer(:visible="editVisible"
        @close="editVisible=false"
        :width="drawerWidth"
        title="更新")
        a-form-model(:form="editForm" :model="editContent")
            a-form-model-item(v-for="(item,index) in columns" 
                :key="index" 
                :label="item.title" 
                :label-col="{span:6}" 
                :wrapper-col="{span:18}")

                span(v-decorator="[item.dataIndex]" v-if="!item.editAble") {{ editContent[item.dataIndex] }}
                
                a-input-number(v-else-if="item.type == 'Number'"
                    style="width:100%"
                    v-decorator="[item.dataIndex]"
                    v-model="editContent[item.dataIndex]"
                    :placeholder="item.title")
                
                a-select(v-else-if="item.type == 'Enum'"
                    v-decorator="[item.dataIndex,]"
                    v-model="editContent[item.dataIndex]")
                    a-select-option(v-for="i in item.enum" 
                        :value="i"
                        :key="i") {{ i }}

                a-input(
                    v-else
                    :placeholder="item.title" 
                    v-model="editContent[item.dataIndex]"
                    v-decorator="[item.dataIndex]")
        .submit-panel 
            a-button(type="primary" @click="submitEdit") Submit
                    
    .ctl-bar 
        
        a-input-search(placeholder="input search text" 
            style="width: 200px; margin-right:20px" 
            v-model="search")

        a-button(type="primary" 
            @click="handleCreate" 
            style=" margin-right:20px" 
            v-if="addAble") 
            a-icon(type="plus")
            span 创建

        a-button(type="primary" 
            style=" margin-right:20px" 
            @click="getData") 
            a-icon(type="redo")
            span 刷新
        

        a-radio-group(button-style="solid" 
            style=" margin-right:20px" 
            default-value="all" v-model="selectFilter" v-if="selectAble")
            a-radio-button(value="all") 全部
            a-radio-button(value="selected") 已选
            a-radio-button(value="notSelected") 未选

        a-upload(name="upload"
            :beforeUpload="beforeUpload"
            :showUploadList="false"
            :multiple="false"
            v-if="uploadAble")
            a-button(type="primary")
                a-icon(type="upload")
                span 上传

    a-table(:columns="theColumns" 
        :dataSource="computedItems"
        :pagination="{pageSize}"
        :rowKey="rowKey" 
        :loading="loading"
        :rowSelection="rowSelection"
        size="small")
        template(slot="operate" slot-scope="record")
            a-popconfirm(title="确认删除？" @confirm="submitDel(record[rowKey])" v-if="deleteAble")
                a 
                    a-icon(type="delete")
            a-divider(type="vertical" v-if="editAble")
            a(@click="handleUpdate(record)" v-if="editAble") 
                a-icon(type="edit")

        template(slot="link" slot-scope="text, record")
            a(@click="handleLink(record)") 
                a-icon(type="link")
                span {{ text }}

        AccountAddress(slot="accountAddress" slot-scope="text, record" :address="text")

        Instrument(slot="inst" slot-scope="text" :code="text")

        TradeAccount(slot="tradeAccount" slot-scope="text, record" :name="text" :id="record._id")
        //- template(slot="expandedRowRender" slot-scope="record")
        //-     slot(name="expand" :redord="record")
</template>

<script>
import _ from 'lodash';
import axios from 'axios';
import moment from 'moment';
import AccountAddress from '../links/AccountAddress.vue';
import Instrument from '../components/Instrument.vue';
import TradeAccount from '../links/TradeAccount.vue';
export default {
    props: {
        rowKey: {
            type: String,
            default: '_id',
        },
        selectAble: {
            type: Boolean,
            default: false
        },
        selectedRowKeys: {
            type: Array,
            default: () => [],
        },
        active: {
            type: Boolean,
        },
        columns: {
            type: Array,
        },
        directRoute: {
            type: String,
        },
        strictRoute: {
            type: String,
        },
        route: {
            type: String,
        },
        addAble: {
            type: Boolean,
            default: true,
        },
        deleteAble: {
            type: Boolean,
            default: true,
        },
        editAble: {
            type: Boolean,
            default: true,
        },
        uploadAble: {
            type: Boolean,
            default: false,
        },
        pageSize: {
            type: Number,
            default: 10
        },
        drawerWidth: {
            type:Number,
            default: 500,
        },
        autoRefresh: {
            type: Number,
            default: 0,
        },
        apiVersion: {
            type: String,
            default: 'v2'
        }
    },
    data() {
        let addColumns = _.filter(this.columns, i => i.addAble);
        let editColumns = _.filter(this.columns, i => i.editAble);
        this.searchColumns = _.filter(this.columns, i => i.searchAble);

        let theColumns = _.clone(this.columns).map(item => {
            if (['createdAt', 'updatedAt'].includes(item.dataIndex)) {
                item.sorter = (a, b) => {
                        const dateA = new Date(a[item.dataIndex]);
                        const dateB = new Date(b[item.dataIndex]);
                        return dateA.getTime() - dateB.getTime();
                    }
            }

            if (item.type == 'Enum') {
                item.filters = item.enum.map(i => ({text: i, value: i}));
                item.onFilter = (value, record) => value == record[item.dataIndex];
            }

            return item;
        });

        if (this.deleteAble || this.editAble) {
            theColumns.push({
                title: '操作',
                scopedSlots: {
                    customRender: 'operate'
                }
            })
        }


        return {
            selectFilter: "all",
            
            theColumns,
            editId: null, 
            editContent: {},
            editColumns,
            editVisible: false,
            editForm: this.$form.createForm(this, {name: 'editform'}),
            addColumns,
            addVisible: false,
            addForm: this.$form.createForm(this, {name: 'addform'}),
            items: [],
            search: '',
            loading: false,
            hasExpand: false,
        }
    },
    mounted() {
        if (this.$slots.expand) {
            console.log('sss')
            this.hasExpand = true;
        }

        this.getData(true);

        if (this.autoRefresh > 0) {
            let timer = setInterval(() => {
                if (this.active) this.getData(false);
            }, this.autoRefresh * 1000);

            this.$once('hook:beforeDestroy', () => {
                console.log('这里使用 hook 监听 beforeDestroy 生命')
                clearInterval(timer);
                timer = null;
            });
        }
    },
    methods: {
        beforeUpload(file) {
            this.$emit("beforeUpload", file);
            return false;
        },
        handleLink(record) {
            this.$emit('link', record);
        },
        handleUpdate(record) {
            this.editId = record[this.rowKey];
            this.editContent = _.clone(record);
            this.editVisible = true;
        },
        submitEdit() {
            this.editForm.validateFields((errors, values) => {
                if (!errors) {
                    let updateContent = {
                        ...this.editContent
                    };
                    updateContent[this.rowKey] = this.editId;

                    this.$api.request('POST', this.route+'/update', updateContent, this.apiVersion)
                        .then(() => {
                            this.editVisible = false;
                            this.$message.success('更新成功');
                            this.getData(true);
                        })
                        .catch(e => {
                            this.$message.error('更新失败');
                        })
                }
            });
        },
        submitDel(id) {
            this.$api.request('DELETE', this.route + '/' + id, {}, this.apiVersion)
                .then(() => {
                    this.getData(true);
                    this.$message.success('删除成功');
                })
                .catch(e => {
                    this.$message.error('删除失败');
                })
        },
        submitAdd() {
            this.addForm.validateFields((errors, values) => {
                if (!errors) {
                    values = _.mapValues(values, v => _.isString(v) ? _.trim(v) : v);
                    
                    this.$api.request('POST', this.route+'/create', values, this.apiVersion)
                        .then(() => {
                            this.addVisible = false;
                            this.$message.success('添加成功');
                            this.getData(true);
                        })
                        .catch(e => {
                            this.$message.error('添加失败');
                        })
                }
            });
        },
        getData(loading=true) {
            this.loading = loading;

            if (this.directRoute) {
                axios.get(this.directRoute)
                    .then(res => {
                        this.items = res.data.map(d => {
                            return {
                                ...d,
                                createdAt: d.createdAt ? moment(d.createdAt).format('YYYY-MM-DD HH:mm:ss') : null,
                                updatedAt: d.updatedAt ? moment(d.updatedAt).format('YYYY-MM-DD HH:mm:ss') : null,
                            }
                        });

                        this.loading = false;
                    });
            }
            else {
                let route = this.strictRoute || this.route + 's';
            
                this.$api.request('GET', route, {}, this.apiVersion)
                    .then(res => {
                        this.items = res.data.map(d => {
                            return {
                                ...d,
                                createdAt: d.createdAt ? moment(d.createdAt).format('YYYY-MM-DD HH:mm:ss') : null,
                                updatedAt: d.updatedAt ? moment(d.updatedAt).format('YYYY-MM-DD HH:mm:ss') : null,
                            }
                        });

                        this.loading = false;
                    })
            }
            
        },
        handleCreate() {
            this.addVisible = true;
        }
    },
    components: {
        AccountAddress,
        Instrument,
        TradeAccount
    },
    computed: {
        rowSelection() {
            if (!this.selectAble) return null;
            return {
                selectedRowKeys: this.selectedRowKeys,
                onChange: (selectedRowKeys, selectedRows) => {
                    this.$emit("selectChange", selectedRowKeys, selectedRows);
                }
            }
        },
        computedItems() {

            return this.items.filter(item => {
                if (this.selectAble) {
                    if (this.selectFilter == 'all') return true;
                    else if (this.selectFilter == 'selected') return this.selectedRowKeys.includes(item[this.rowKey]);
                    else if (this.selectFilter == 'notSelected') return !this.selectedRowKeys.includes(item[this.rowKey]);
                } 
                else return true;
            }).filter(item => {
                if (this.search) {
                    if (this.searchColumns.length == 0) return true;

                    let search = this.search.toLocaleLowerCase().trim();

                    return _.find(this.searchColumns, col => {
                        let found = item[col.dataIndex].toLocaleLowerCase().includes(search);

                        return found;
                    });
                }

                return true;
            });
        }
    }
}
</script>

<style lang="less">
.submit-panel {
    position: absolute;
    right: 0;
    bottom: 0;
    width: 100%;
    border-top: 1px solid #e9e9e9;
    padding: 10px 16px;
    background: #fff;
    text-align: right;
    z-index: 1;
}
</style>
<template lang="pug">
    .page 
        a-drawer(title="新增"
            @close="addVisible=false"
            :width="600"
            :visible="addVisible")
            a-form(:form="addForm" style="padding-bottom:30px")
                a-form-item.detail-row(v-for="(item,index) in addColumns" 
                        :key="index" 
                        :label="item.title" 
                        :label-col="{span:10}" 
                        :wrapper-col="{span:14}")
    
                        a-input-number(v-if="item.type == 'Number' && item.percentDisplay"
                            style="width:180px"
                            :min="item.min"
                            :max="item.max"
                            :formatter="percentFormatter"
                            :parser="percentParser"
                            v-decorator="[item.dataIndex,{initialValue: item.default*100 || 0}]"
                            :placeholder="item.title")

                        a-input-number(v-else-if="item.type == 'Number'"
                            style="width:180px"
                            :min="item.min"
                            :max="item.max"
                            v-decorator="[item.dataIndex,{initialValue: item.default || 0}]"
                            :placeholder="item.title")
    
                        a-radio-group(v-else-if="item.type == 'EnumRadio'" 
                            style="width:180px"
                            button-style="solid"
                            v-decorator="[item.dataIndex, {initialValue: item.default }]")
                            a-radio-button(v-for="i in item.enum" 
                                :value="i"
                                :key="i") {{ i }}

                        a-select(v-else-if="item.type == 'Enum'"
                            v-decorator="[item.dataIndex,{initialValue: item.default }]"
                            style="width: 180px"
                            v-model="editContent[item.dataIndex]")
                            a-select-option(v-for="i in item.enum" 
                                :value="i"
                                :key="i") {{ i }}

                        a-switch(v-else-if="item.type == 'Boolean'" 
                            style="width:60px"
                            v-decorator="[item.dataIndex, {valuePropName: 'checked', initialValue: item.default}]")
                            
                        
                        a-date-picker(v-else-if="item.type == 'Date'"
                            value-format="YYYY-MM-DD"
                            v-decorator="[item.dataIndex, [{ type: 'object', required: true, message: 'Please select time!' }]]")
    
                        a-input(
                            v-else
                            :placeholder="item.title" 
                            style="width: 180px"
                            v-decorator="[item.dataIndex,]")
            .submit-panel 
                a-button(type="primary" @click="submitAdd") Submit
    
        a-drawer(:visible="editVisible"
            @close="editVisible=false"
            :width="600"
            title="更新")
            a-form-model(:form="editForm" :model="editContent")
               
                a-form-model-item.detail-row(v-for="(item,index) in allCols" 
                    :key="index" 
                    :label="item.title" 
                    :label-col="{span:10}" 
                    :wrapper-col="{span:14}")
    
                    span(v-decorator="[item.dataIndex]" v-if="(!item.editAble &&  editStatus == 'on') || (editStatus == 'off' && !editAbleAfterDoneColumns.includes(item.dataIndex))") {{ editContent[item.dataIndex] }}
                    
                    a-input-number(v-else-if="item.type == 'Number' && item.percentDisplay"
                        style="width:180px"
                        :min="item.min"
                        :max="item.max"
                        :formatter="percentFormatter"
                        :parser="percentParser"
                        v-model="editContent[item.dataIndex]"
                        v-decorator="[item.dataIndex,{initialValue: item.default || 0}]"
                        :placeholder="item.title")

                    a-input-number(v-else-if="item.type == 'Number'"
                        style="width:180px"
                        :min="item.min"
                        :max="item.max"
                        v-model="editContent[item.dataIndex]"
                        v-decorator="[item.dataIndex,{initialValue: item.default || 0}]"
                        :placeholder="item.title")
                    
                    a-radio-group(v-else-if="item.type == 'EnumRadio'"
                        v-decorator="[item.dataIndex,]"
                        button-style="solid"
                        style="width: 180px"
                        v-model="editContent[item.dataIndex]")
                        a-radio-button(v-for="i in item.enum" 
                            :value="i"
                            :key="i") {{ i }}

                    a-select(v-else-if="item.type == 'Enum'"
                        v-decorator="[item.dataIndex,]"
                        style="width: 180px"
                        v-model="editContent[item.dataIndex]")
                        a-select-option(v-for="i in item.enum" 
                            :value="i"
                            :key="i") {{ i }}

                    a-switch(v-else-if="item.type == 'Boolean'" 
                        style="width:60px"
                        v-model="editContent[item.dataIndex]"
                        v-decorator="[item.dataIndex, {valuePropName: 'checked',}]")
    
                    a-date-picker(v-else-if="item.type == 'Date'"
                        value-format="YYYY-MM-DD"
                        v-model="editContent[item.dataIndex]"
                        v-decorator="[item.dataIndex, [{ type: 'object', required: true, message: 'Please select time!' }]]")
    
                    a-input(
                        v-else
                        :placeholder="item.title" 
                        style="width: 180px"
                        v-model="editContent[item.dataIndex]"
                        v-decorator="[item.dataIndex]")
            .submit-panel 
                a-button(type="primary" @click="submitEdit") Submit
    
        
        .ctl-bar
            a-input-search(placeholder="input search text" 
                style="width: 200px; margin-right:20px" 
                v-model="search")
    
            a-button(type="primary" 
                @click="addVisible=true" 
                v-if="!viewOnly"
                style=" margin-right:20px") 
                a-icon(type="plus")
                span 创建
    
            a-button(type="primary" 
                style=" margin-right:20px" 
                @click="getData") 
                a-icon(type="redo")
                span 刷新
            
            a-button(type="primary" 
                style=" margin-right:20px"
                v-if="!viewOnly"
                @click="handleEditCol") 
                a-icon(type="edit")
                span 编辑可视列

            a-button(type="primary"
                style="margin-right:20px"
                @click="saveCsv")
                a-icon(type="export")
                span 导出CSV

            span
                span Client:
                a-select(style="width:120px" v-model="filterClient")
                    a-select-option(v-for="i in clients" :value="i" :key="i") {{ i }}
    
        a-table.option-table(:columns="computedColumns" 
            bordered 
            :dataSource="computedItems"
            rowKey="id"
            :scroll="computedScroll"
            :rowClassName="getRowClassName"
            :pagination="{pageSize: 50}"
            
            size="small")
            template(slot="operate" slot-scope="record" v-if="!viewOnly")
                a-popconfirm(title="确认删除？" @confirm="submitDel(record.id)")
                    a 
                        a-icon(type="delete")
    
                a-divider(type="vertical")
                a(@click="handleUpdate(record)") 
                    a-icon(type="edit")
            
            template(slot="percentDisplay" slot-scope="text")
                span {{Math.round(text*100000)/1000}}%

            template(slot="numberDisplay", slot-scope="text")
                span {{isNaN(text) ? '--' : Math.round(text * 10000)/10000}}

            template(slot="boolDisplay", slot-scope="text")
                span {{text ? 'yes': 'no'}}
            //- Instrument(slot="inst" slot-scope="text,record" :code="text" :rowSpan="2")
    
</template>

<script>
import moment from 'moment';
import _ from 'lodash';
import Instrument from '../../components/Instrument.vue'

export default {
    props: ['allCols', 'columns', 'viewOnly', 'status', 'getStockCodeColor', 'bookName', 'rowSpanColumns', 'autoRefresh'],
    data() {

        return {
            addVisible: false,
            editVisible: false,
           
            editContent: {},
            editStatus: 'on',
            addForm: this.$form.createForm(this, {name: 'addform', onValuesChange: this.handleAddValueChange}),
            editForm: this.$form.createForm(this, {name: 'editform'}),
            
            editAbleAfterDoneColumns: ['close_date', 'curr_stock_price', 'use_set_option_price', 'set_option_price'],
            items: [],
            
            search: '',
            searchColumns: ['stock_code', 'stock_name'],
            clients: ['all', '星光', '中金EQ', '中金财富', '广发香港', '申万香港', '国君UK', '华泰', '自有产品'],
            filterClient: 'all',
        }
    },
    mounted() {
        this.getData();

        if (this.autoRefresh > 0) {
            

            let timer = setInterval(() => {
                this.getData(false);
            }, this.autoRefresh * 1000);

            this.$once('hook:beforeDestroy', () => {
                console.log('这里使用 hook 监听 beforeDestroy 生命 HKOPtion')
                clearInterval(timer);
                timer = null;
            });
        }
    },
    methods: {
        handleAddValueChange(props, values) {
            let {first_trade_date} = props;
            let {order_date} = values;
            if (order_date && !first_trade_date) {
                this.addForm.setFieldsValue({first_trade_date: order_date});
            }
                // console.log(props, values);
        },
        getRowClassName(record, index) {

            let stock_code = record['stock_code'];
            return this.getStockCodeColor(stock_code);
        },
        handleEditCol() {
            this.$emit("editCol");
        },
        handleUpdate(record) {
            this.editId = record.id;
            this.editContent = _.clone(record);
            this.percentKeys.map(key => this.editContent[key] = Math.round(this.editContent[key]*100000)/1000);

            this.editVisible = true;
            this.editStatus = record.status;
        },
        
        getData(loading=true) {
            this.loading = loading;

            this.$api.request('GET', `hkoption/${this.bookName}/options`, {status: this.status})
                .then(res => {
                    this.items = res.data.map(d => {
                        return {
                            ...d,
                            // createdAt: d.createdAt ? moment(d.createdAt).format('YYYY-MM-DD HH:mm:ss') : null,
                        }
                    });

                    this.loading = false;
                })
        },
        
        submitAdd() {
             
            this.addForm.validateFields((errors, values) => {
                if (!errors) {
                    values = _.mapValues(values, v => _.isString(v) ? _.trim(v) : v);
                    let valueKeys = _.keys(values);
                    this.percentKeys.map(key => {
                        if (valueKeys.includes(key)) values[key] = Math.round(values[key] * 1000)/100000;
                    });

                    this.$api.request('POST', `hkoption/${this.bookName}/option/create`, values)
                        .then(() => {
                            this.addVisible = false;
                            this.$message.success('添加成功');
                            this.getData(true);
                        })
                        .catch(e => {
                            this.$message.error('添加失败');
                        })
                }
            });
        },
        submitEdit() {
            let route = this.status == 'on' ? `hkoption/${this.bookName}/option/update` : `hkoption/${this.bookName}/option/updateDone`;

            this.editForm.validateFields((errors, vals) => {
                if (!errors) {
                    let values = _.clone(this.editContent);
                    let valueKeys = _.keys(values);
                    this.percentKeys.map(key => {
                        if (valueKeys.includes(key)) values[key] = Math.round(values[key] * 1000)/100000;
                    });

                    console.log(values);

                    this.$api.request('POST', route, {
                        // ...this.editContent,
                        ...values,
                        id: this.editId,
                    })
                        .then(() => {
                            this.editVisible = false;
                            this.$message.success('更新成功');
                            this.getData(true);
                        })
                        .catch(e => {
                            this.$message.error('更新失败');
                        })
                }
            });
            
        },
        submitDel(id) {
            this.$api.request('DELETE', `hkoption/${this.bookName}/option/${id}`)
                .then(() => {
                    this.getData(true);
                    this.$message.success('删除成功');
                })
                .catch(e => {
                    this.$message.error('删除失败');
                })
        },
        percentParser(value) {
            // return Number(value.replace('%', ''))/100
            return value.replace('%', '');
        },
        percentFormatter(value) {
            return `${value}%`
            // return `${Math.round(Number(value)*100000)/1000}%`
        },
        saveCsv() {
            let today = moment().format('YYYY-MM-DD');
            let head = this.computedColumns.filter(i => i.title != '操作').map(i => i.title).join(',') + '\n';


            let selectedCols = this.computedColumns.filter(i => i.title != '操作').map(i => i.dataIndex);

            let content = this.computedItems.map(item => {
                return _.values(_.pick(item, selectedCols)).join(',');
            }).join('\n');


            let blob = new Blob([head + content], {type: 'text/plain;charset=utf-8'});
            let url = URL.createObjectURL(blob);
            let a = document.createElement('a');
            a.href = url;
            if (this.status == 'on') {
                a.download = `OptionPage_${today}.csv`;
            }
            else if (this.status == 'off') {
                a.download = `DoneTrades_${today}.csv`;
            }
            
            document.body.appendChild(a);
            a.click();

            setTimeout(() => {
                URL.revokeObjectURL(url);
            }, 100);
        }
    },
    components: {Instrument},
    computed: {
        computedAllColumns() {
            let cols =  this.allCols.map(col => {
                return {
                    ...col,
                    editAble: this.editAbleAfterDoneColumns.includes(col.dataIndex)
                }
            });
            cols = _.sortBy(cols, (i) => !i['editAble']);
            return cols;
            
        },
        addColumns() {
            let keys = ['stock_code', 'flag', 'side', 'order_date', 'strike_price', 'ext_sell_price', 'first_trade_date', 'expiry_date', 'notional', 'init_price', 'hedging_vol',  'set_vola', 'risk_free_rate', 'dividend_yield', 'use_set_vola', 'client'];

            return this.allCols.filter(col => keys.includes(col.dataIndex));
        },
        computedScroll() {
            let pageHeight = Math.max(document.documentElement.scrollHeight, document.body.scrollHeight);
            let isMac = /mac os/ig.test(navigator.userAgent);

            if (isMac) {
                return { x: Math.max(90*this.computedColumns.length, 1200)}
            }
            else {
                return { x: Math.max(90*this.computedColumns.length, 1200), y: pageHeight*0.7 }
            }

            
        },
        percentKeys() {
            return this.allCols.filter(i => i.percentDisplay).map(i => i.dataIndex);
        },
        computedColumns() {
            let cols = this.columns.map(col => {
                if (this.rowSpanColumns.includes(col.dataIndex)) {
                    return {
                        ...col,
                        customRender: (value, row, index) => {
                            if (col.percentDisplay) {
                                value = `${Math.round(value*100000)/1000}%`;
                            }
                            else if (col.type == 'Number') {
                                value = Math.round(value * 10000)/10000;
                            }

                            const obj = {
                                children: value,
                                attrs: {
                                    rowSpan: row.rowSpan
                                },
                            };
                            return obj;
                        },
                    };
                }
                else {
                    return col;
                }
            });
            
            if (this.viewOnly) {
                cols = cols.filter(col => col.title != '操作');
            }
            return cols;
        },
        computedItems() {
            return _.chain(this.items)
                .filter(item => {
                    if (this.search) {
                        if (this.searchColumns.length == 0) return true;

                        let search = this.search.toLocaleLowerCase().trim();

                        return _.find(this.searchColumns, col => {
                            let found = item[col].toLocaleLowerCase().includes(search);

                            return found;
                        });
                    }

                    if (this.filterClient != 'all') {
                        return item.client == this.filterClient;
                    }

                    return true;
                })
                .groupBy('stock_code')
                .toPairs()
                .map(([k, v]) => {
                    let values = v.map((item, index) => {
                        if (index == 0) item.rowSpan = v.length ;
                        else item.rowSpan = 0;
                        
                        // summary.hedging_gain_loss += item.hedging_gain_loss;
                        // summary.total_daily_pnl += item.total_daily_pnl;
                        // summary.total_pnl += item.total_pnl;
                        // summary.option_mark_up += item.option_mark_up;
                        // summary.option_daily_pnl += item.option_daily_pnl;
                        // summary.option_pnl += item.option_pnl;
                        // summary.instrinsic_value += item.instrinsic_value;
                        // summary.stock_daily_pnl += item.stock_daily_pnl;
                        // summary.cumulative_stock_pnl += item.cumulative_stock_pnl;
                        // summary.time_value += item.time_value;
                        // summary.notional += item.notional;
                        // summary.theta += item.theta;
                        // summary.vega += item.vega;
                        // summary.rho += item.rho;
                        // summary.gamma += item.gamma;

                        return item;
                    });
                    // values.push(summary);

                    return values;
                })
                .flatten()
                // .sortBy('stock_code')
                .value();

        },
    },

}
</script>

<style lang="less">

.hk-option-row-1 {
    background: rgba(255, 192, 203, 0.5);
    font-size: 12px;
}
.hk-option-row-2 {
    background: rgba(0, 255, 255, 0.5);
    font-size: 12px;
}
.hk-option-row-3 {
    background: rgba(255, 166, 0, 0.5);
    font-size: 12px;
}
.hk-option-row-4 {
    background: rgba(0, 128, 0, 0.5);
    font-size: 12px;
}
.hk-option-row-5 {
    background: rgba(128, 0, 128, 0.5);
    font-size: 12px;
}
.hk-option-row-6 {
    background: rgba(0, 0, 255, 0.397);
    font-size: 12px;
}
.hk-option-row-7 {
    background: #87d06886;
    font-size: 12px;
}

.detail-row {
    margin: 0px;
    padding: 0px;
    font-size: 13px;
}

.option-table {

// ::-webkit-scrollbar-thumb {
//   border-radius: 4px;
//   background-color: rgba(0, 0, 0, .5);
// //   box-shadow: 0 0 1px rgba(255, 255, 255, .5);
// }
}
</style>
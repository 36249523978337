<template lang="pug">
.page 
    a-tabs 
        a-tab-pane(key="user" tab="用户管理")
            User
        a-tab-pane(key="folder" tab="文件管理")
            File
        a-tab-pane(key="tradeAccount" tab="交易账户管理")
            TradeAccount
        a-tab-pane(key="log" tab="日志管理")
            Log
</template>

<script>
import User from './User.vue'
import File from './File.vue'
import TradeAccount from './TradeAccount.vue'
import Log from './Log.vue'

export default {
    data() {
        return {

        };
    },
    components: {
        User,
        File,
        TradeAccount,
        Log
    }
}
</script>            
<template lang="pug">
.page 
    a-table(:columns="columns" 
        rowKey="id" 
        size="small"
        :rowClassName="getRowClassName"
        :pagination="{pageSize: 50}"
        :dataSource="items")
</template>
<script>
import _ from 'lodash';

export default {
    props: ['bookName', 'getStockCodeColor'],
    data() {
        return {
            items: [],
            columns: [
                {
                    title: 'symbol',
                    dataIndex: 'symbol',  
                },
                {
                    title: 'stock_code',
                    dataIndex: 'stock_code',  
                },
                {
                    title: 'stock_name',
                    dataIndex: 'stock_name',  
                },
                {
                    title: 'option_ava_vola',
                    dataIndex: 'option_ava_vola',  
                },
                {
                    title: 'pnl_up_std_1',
                    dataIndex: 'pnl_up_std_1',  
                },
                {
                    title: 'pnl_up_std_2',
                    dataIndex: 'pnl_up_std_2',  
                },
                {
                    title: 'pnl_up_std_3',
                    dataIndex: 'pnl_up_std_3',  
                },
                {
                    title: 'pnl_up_pct_10',
                    dataIndex: 'pnl_up_pct_10',  
                },
                {
                    title: 'pnl_down_std_1',
                    dataIndex: 'pnl_down_std_1',  
                },
                {
                    title: 'pnl_down_std_2',
                    dataIndex: 'pnl_down_std_2',  
                },
                {
                    title: 'pnl_down_std_3',
                    dataIndex: 'pnl_down_std_3',  
                },
                {
                    title: 'pnl_down_pct_10',
                    dataIndex: 'pnl_down_pct_10',  
                }
            ]
        }
    },
    mounted() {
        this.getData();
    },
    methods: {
        getData() {
            this.$api.request('GET', `hkOption/${this.bookName}/scenario_analysis`)
                .then(res => {
                    this.items = res.data.map(i => {
                        return {
                            ...i,
                            option_ava_vola: Math.round(i.option_ava_vola*100000)/100000,
                            pnl_up_std_1: Math.round(i.pnl_up_std_1*100000)/100000,
                            pnl_up_std_2: Math.round(i.pnl_up_std_2*100000)/100000,
                            pnl_up_std_3: Math.round(i.pnl_up_std_3*100000)/100000,
                            pnl_up_pct_10: Math.round(i.pnl_up_pct_10*100000)/100000,
                            pnl_down_std_1: Math.round(i.pnl_down_std_1*100000)/100000,
                            pnl_down_std_2: Math.round(i.pnl_down_std_2*100000)/100000,
                            pnl_down_std_3: Math.round(i.pnl_down_std_3*100000)/100000,
                            pnl_down_pct_10: Math.round(i.pnl_down_pct_10*100000)/100000,
                        }
                    });
                })
        },
        getRowClassName(record, index) {
            let stock_code = record['stock_code'];
            return this.getStockCodeColor(stock_code);
        },
    }
}
</script>
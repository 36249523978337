<template lang="pug">
.page 
    z-table(:columns="columns" 
        :addAble="false"
        :editAble="false"
        @link="go"
        strictRoute="tradeAccount/user")
</template>

<script>
export default {
    data() {
        return {
            columns: [
                {
                    title: 'Name',
                    dataIndex: 'name',
                    searchAble: true,
                    scopedSlots: {
                        customRender: 'link'
                    }
                },
                {
                    title: 'Account',
                    dataIndex: 'account',
                    searchAble: true,
                },
                {
                    title: 'Broker',
                    dataIndex: 'broker',
                    searchAble: true,
                },
                {
                    title: 'desc',
                    dataIndex: 'desc',
                    searchAble: true,
                },
                {
                    title: 'Created At',
                    dataIndex: 'createdAt',
                }
            ]
        }
    },
    methods: {
        go(record) {
            console.log(record._id);
            window.open(`/trade/index.html#${record._id}`, '_blank');
        }
    }
}
</script>
<template lang="pug">
div 
    a-drawer(:visible="visible"
        @close="visible=false"
        width="70%"
        :title="name")
        img(:src="src" style="width:100%")
    a(@click="visible=true") {{name}}
</template>

<script>
const host = '/api/fs';

export default {
    props: ['name', 'id'],
    data() {
        return {
            visible: false
        }
    },
    computed: {
        src() {
            return `${host}/download/${this.id}`
        }
    }
}
</script>

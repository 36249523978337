<template lang="pug">
.page 
    a-modal(title="编辑可视列" 
        @ok="submitEditCol"
        @cancel="editColVisible=false"
        :width="1000"
        :visible="editColVisible")
        a-raw 
            a-col(:span="8")
                h3 未选
                draggable.col-edit-pane(:list="disSelectedCols" group="colEdit")
                    .col-edit-item(v-for="i in disSelectedCols" :key="i.dataIndex") {{i.title}}
                
            a-col(:span="8")
                h3 已选
                draggable.col-edit-pane(:list="selectedCols" group="colEdit")
                    .col-edit-item(v-for="i in selectedCols" :key="i.dataIndex") {{i.title}}
            
            a-col(:span="8")
                h3 左侧固定
                draggable.col-edit-pane(:list="fixedSelectCols" group="colEdit")
                    .col-edit-item(v-for="i in fixedSelectCols" :key="i.dataIndex") {{i.title}}
    a-tabs(tab-position="right")
        a-tab-pane(v-for="book_name in bookNames" :tab="book_name" :key="book_name"  v-if="me.perms.includes('hkOptionAdmin') || perm.tabs == 'all' || perm.tabs.includes(book_name)")
            a-tabs(default-active-key="on")
                a-tab-pane(key="on" tab="Option Page")
                    TabContent(:all-cols="allCols" 
                        :bookName="book_name"
                        :columns="columns" 
                        :autoRefresh="5"
                        :rowSpanColumns="['stock_name', 'stock_code', 'curr_stock_contract', 'curr_stock_price', 'cum_stock_pnl', 'delta_diff', 'delta_diff_pct', 'action', 'delta_hedging', 'buy_sell', 'hedging_gain_loss', 'total_pnl', 'hedging_threshold', 'max_delta_deviation', 'trader_confidence', 'cumulative_stock_pnl', 'total_daily_pnl', 'stock_daily_pnl', 'price_diff_pct']"
                        :getStockCodeColor="getStockCodeColor"
                        status='on'
                        @editCol="editColVisible=true" 
                        :viewOnly="perm.perms == 'r'")

                a-tab-pane(key="done" tab="Done Trades")
                    TabContent(:all-cols="allCols" 
                        :bookName="book_name"
                        :columns="columns" 
                        status='off'
                        :autoRefresh="0"
                        :rowSpanColumns="['stock_name', 'stock_code']"
                        :getStockCodeColor="getStockCodeColor"
                        @editCol="editColVisible=true" 
                        :viewOnly="true")

                a-tab-pane(key="stock" tab="Stock Page")
                    StockPage(:bookName="book_name")

                a-tab-pane(key="scenario" tab="Scenario Analysis")
                    Scenario(:bookName="book_name" 
                        :getStockCodeColor="getStockCodeColor")
                
                a-tab-pane(key="summary" tab="Summary")
                    Summary(:bookName="book_name")

                a-tab-pane(key="pnl" tab="History PNL")
                    z-table(:columns="pnlColumns"
                        :route="`hkoption/${book_name}/pnl`"
                        :editAble="false"
                        :addAble="false")

        a-tab-pane(key="bookname" tab="BookName管理" v-if="me.perms.includes('hkOptionAdmin')")
            z-table(:columns="bookNameColumns"
                :editAble="false"
                route="hkoption/bookname")
</template>

<script>

import TabContent from './TabContent.vue';
import Scenario from './Scenario.vue';
import Summary from './Summary.vue';
import StockPage from './Stock.vue';
import { editAbleColumns, SalesInputColumns, InitialDataColumns, CurrentPositionColumns, HedgingColumns, BookingColumns } from './columns';
import _ from 'lodash';
import draggable from 'vuedraggable';
import { mapState } from 'vuex';

export default {
    data() {        
        let allCols = _.concat(editAbleColumns, SalesInputColumns, InitialDataColumns, CurrentPositionColumns, HedgingColumns, BookingColumns);

        return {
            userPerms: {
                middledesk1: ['forwarded_trade'],
                risk_review01: ['forwarded_trade'],
                'risk_review-LIK': ['EverCrest 1', 'YuanFamilyOffice 1']
            },

            allCols,
            editColVisible: false,
            disSelectedCols: [],
            selectedCols: [],
            fixedSelectCols: [],
            columns: [],
            bookNames: [],
            stockCodeColors: {},
            bookNameColumns: [
                {
                    title: 'Book Name',
                    dataIndex: 'name',
                    addAble: true,
                },
                {
                    title: '创建时间',
                    dataIndex: 'createdAt'
                }
            ],
            pnlColumns: [
                {
                    title: 'Date',
                    dataIndex: 'date'
                },
                {
                    title: 'Total Pnl',
                    dataIndex: 'pnl'
                }
            ],
        }
    },
    mounted() {
        this.getPerm().then(() => {
            this.getSelectCols();
            this.getBookNames();
        });
        
    },
    computed: {
        bookNamePerm() {
            return this.userPerms[this.me.name];
        },
        ...mapState('user', ['me']),
    },
    methods: {
        async getPerm() {
            let res = await this.$api.request('GET', 'hkoption/perms', {});
            this.perm = res.data;
        },
        getStockCodeColor(stock_code) {
            let className = this.stockCodeColors[stock_code];

            if (!className) {
                let l = _.keys(this.stockCodeColors).length;
                let c = `hk-option-row-${l%7 + 1}`;
                this.stockCodeColors[stock_code] = c;
                return c;
            }
            return className;
        },
        submitEditCol() {
            this.$api.request('POST', 'hkoption/selectCols', {
                cols: this.selectedCols.map(i => i.dataIndex),
                fixedCols: this.fixedSelectCols.map(i => i.dataIndex),
            }).then(res => {
                this.getSelectCols();
                this.editColVisible = false;
            });
        },
        getUsers() {
            this.$api.request("GET", 'users')
            .then(res => {
                this.users = res.data;
            });
        },
        getBookNames() {
            this.$api.request('GET', 'hkoption/booknames')
                .then(res => {
                    this.bookNames = res.data.map(i => i.name);
                    
                });
        },
        getSelectCols() {
            this.$api.request('GET', 'hkoption/selectCols')
                .then(res => {
                    let selectKeys = _.uniq(_.concat(res.data.fixedCols, res.data.cols));

                    let fixedSelectKeys = res.data.fixedCols;

                    this.disSelectedCols = this.allCols.filter(i => !selectKeys.includes(i.dataIndex));
                    
                    let colValue = _.chain(this.allCols)
                        .map(i => [i.dataIndex, i])
                        .fromPairs()
                        .value();
                    
                    this.selectedCols = selectKeys.map(i => colValue[i]).filter(i => !!i);
                    this.fixedSelectCols = fixedSelectKeys.map(i => colValue[i]).filter(i => !!i);

                    this.columns = _.concat(this.selectedCols, [{
                        title: '操作',
                        width: 70,
                        fixed: 'right',
                        scopedSlots: {
                            customRender: 'operate'
                        }
                    }]).map(i => {
                        if (fixedSelectKeys.includes(i.dataIndex)) {
                            i.fixed = 'left';
                            i.width = i.width || 90;
                        }

                        if (i.percentDisplay) {
                            i.scopedSlots = {
                                customRender: 'percentDisplay'
                            }
                        }
                        else if (i.type == 'Number') {
                            i.scopedSlots = {
                                customRender: 'numberDisplay'
                            }
                        }
                        else if (i.type == 'Boolean') {
                            i.scopedSlots = {
                                customRender: 'boolDisplay'
                            }
                        }
                        return i;
                    });
                })
        },
    },
    components: {TabContent, draggable, Scenario, Summary, StockPage}
}
</script>

<style lang="less" scoped>

.col-edit-pane {
    background: rgb(210, 210, 210);
    height: 600px;
    overflow: auto;
    padding: 5px;
}

.col-edit-item {
    cursor: move;
    background: white;
    margin: 2px;
    padding: 5px;
}
</style>

<template lang="pug">
.page
    a-modal(v-model="addSubGroupVisiable"
        title="新建文件夹"
        @ok="submitAddSubGroup")
        a-input(v-model="newSubGroup")

    h3.empty-folder(v-if="groups.length == 0") 文件夹为空，请联系管理员！
    .folder(v-else)
        .folder-tree
            a.folder-root-item(v-for="g in groups" :key="g.name" @click="gotoGroup(g.name)") 
                a-icon(type="folder" theme="filled" style="color:#79cdfd" v-if="currentGroup != g.name")
                a-icon(type="folder-open" style="color:#79cdfd" theme="filled" v-else)
                span {{g.name}}
            
            //- a-directory-tree(:treeData="treeData" @select="handleSelect")

        .folder-content
            .folder-header
                a-breadcrumb 
                    a-breadcrumb-item 
                        a(@click="gotoRoot")
                            a-icon(type="folder" theme="filled" style="color:#79cdfd")
                            span {{currentGroup}}
                    a-breadcrumb-item(v-if="currentSubGroup != 'root'")
                        span {{currentSubGroup}}
            a-row(v-if="currentSubGroup == 'root'") 
                a-col(:span="3" v-for="g in currentSubGroups" :key="g" v-if="g != 'root'")
                    a.folder-item(@click="gotoSub(g)")
                        a-icon(type="folder" style="font-size:30px;color:#79cdfd" theme="filled")
                        div {{g}} 
                

            Folder(:group="currentGroup" :subGroup="currentSubGroup" :subGroups="currentSubGroups" :key="groupKey")

</template>
<script>
import {mapState} from 'vuex';
import Folder from '../components/folder/Folder.vue';

export default {
    data() {
        return {
            addSubGroupVisiable: false,
            groupKey: '',
            newSubGroup: '',
            currentGroup: '',
            currentSubGroup: '',
            currentSubGroups: [],
            groups: [],
            
        };
    },
    mounted() {
        this.getData();
    },
    methods: {
        gotoGroup(group) {
            this.currentGroup = group;
            this.currentSubGroup = 'root';
            this.groupKey = group;
            this.currentSubGroups = this.groups.find(g => g.name == group).subGroups;
        },
        gotoSub(subGroup) {
            this.currentSubGroup = subGroup;
            this.groupKey = this.currentGroup + '/' + subGroup;
        },
        gotoRoot() {
            this.currentSubGroup = 'root';
            this.groupKey = this.currentGroup;
        },
        handleSelect(key) {
            const keyValue = key[0];
            const keyArr = keyValue.split('/');
        
            this.currentGroup = keyArr[0];
            if (keyArr.length > 1)
                this.currentSubGroup = keyArr[1];
            else {
                this.currentSubGroup = 'root';
                
            }
            this.currentSubGroups = this.groups.find(g => g.name == keyArr[0]).subGroups;
            this.groupKey = keyValue;
        },
        submitAddSubGroup() {
            this.$api.request('POST', 'subGroup/add/' + this.currentGroup, {subGroup: this.newSubGroup}, 'fs')
                .then(res => {
                    this.getData();
                    this.addSubGroupVisiable = false;
                });
        },
        submitDelSubGroup(subGroup) {
            this.$api.request('POST', 'subGroup/del/' + this.currentGroup, {subGroup}, 'fs')
                .then(res => {
                    this.getData();
                });
        },
        getData() {
            this.$api.request('GET', 'groups/' + this.me._id, {}, 'fs')
                .then(res => {
                    this.groups = res.data;
                    // if (!this.currentGroup && this.groups.length > 0) {
                    //     this.currentGroup = this.groups[0]['name'];
                    // }
                });
        },  
        handleEdit(targetKey, action) {
            console.log(targetKey, action);
            if (action == 'add') this.addSubGroupVisiable = true;
            else if (action == 'remove') {
                this.$confirm({
                    title: '确定删除' + targetKey + '?',
                    onOk: () => {
                        this.submitDelSubGroup(targetKey);
                    }
                });
            }
        }
    },
    computed: {
        ...mapState('user', ['me']),
        treeData() {
            return this.groups.map(g => {
                return {
                    title: g.name,
                    key: g.name,
                    children: g.subGroups.filter(sub => sub != 'root').map(sub => {
                        return {
                            title: sub,
                            key: `${g.name}/${sub}`
                        };
                    })
                };
            });
        }
    },
    components: {
        Folder
    }
}
</script>

<style lang="less" scoped>
.empty-folder{
    display: block;
    margin: auto;
    width: 100%;
    text-align: center;
    font-size: 20px;
}
.folder {
    display: flex;
    flex-direction: row;
}
.folder-tree {
    width: 300px;
    background-color: rgb(210, 210, 210);
    height: calc(100vh - 50px);
    /* height: 100vh - 40px; */
    overflow: auto;
}
.folder-content {
    flex: 1;
}
.folder-header {
    padding: 10px;
    background-color: rgb(230, 230, 230);
    font-size: 18px;
}
.folder-item {
    display: flex;
    flex-direction: column;
    align-items: center;
    // margin-bottom: 10px;
    margin: 0 20px 10px 20px;
    
}
.folder-item:hover {
    background-color: rgb(211, 211, 211);
}
.folder-root-item {
    display: block;
    font-size: 18px;
    padding: 8px;
    // margin: 3px 0;
    border: solid 1px #ddd;
    display: flex;
    flex-direction: row;
    align-items: center;
}
.folder-root-item:hover {
    background-color: white;
}
</style>